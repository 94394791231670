export const isProduction = process.env.NODE_ENV === "production";
const prodBackEndUrl = "http://propnovaadminapi.vmortech.com.au"; //"https://dev-pmadminAPI.PropNova.com.au"; 

export const backEndBaseURL = isProduction ? prodBackEndUrl : 'http://192.168.15.253:5001';
// export const googleMapApiKey = "AIzaSyD6710nxRY5KzD5rfHOjaFFE_diB7b8AQA"; // VMOR KEY
export const googleMapApiKey = "AIzaSyBkkEWSbZqrLyfdGyLdKTIqrI_ib3Mt5TE"; // PIA API KEY

export const encryptedPassword = "*ENCRYPTEDPASSWORD*";

export const version = "0.1.29.4";


