import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { PAGE_SIZE, PlatformType, VmList } from '../constants/options';
import { SetLocalStorage } from '../utilities/cryptography';
import { AREA_CATEGORY, JOB_CATEGORY } from '../constants/storage-keys';
import { FAxios } from '../utilities/file-network';

export default class ContactStore {

  loading: boolean = false;

  loadingInfo: boolean = false;

  downloading: boolean = false;

  contacts: VmList = { totalCount: 0, items: [] };

  tenants: VmList = { totalCount: 0, items: [] };

  owners: VmList = { totalCount: 0, items: [] };

  bms: VmList = { totalCount: 0, items: [] };

  contractors: VmList = { totalCount: 0, items: [] };

  contactInfo: any = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingInfo: observable,
      contacts: observable,
      contactInfo: observable,
      downloading: observable,
    });
    this.rootStore = rootStore;
  }

  searchContacts = async (skip: number, take: number, userModel: any = {}, role?: "Tenant" | "Owner" | "Contractor" | "BM") => {
    this.loading = true;
    try {
      // const str = 'properties,propertyowners,propertytenants,jobsummary,attachments,categoryids,coveredareas'
      const res = await Axios.post(`/api/Contact/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      if (!role) this.contacts = res.data;
      else if (role === "Tenant") this.tenants = res.data;
      else if (role === "Owner") this.owners = res.data;
      else if (role === "BM") this.bms = res.data;
      else this.contractors = res.data;
      console.log("Contact", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchContactsRemovedObj = async (skip: number, take: number, userModel: any = {}, role?: "Tenant" | "Owner" | "Contractor" | "BM",removedObj='properties,propertyowners,propertytenants,jobsummary,attachments,categoryids,coveredareas,jobCategoryMapsAttachmentMaps,') => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Contact/Search/${skip}/${take}?sRemoveObj=${removedObj}&platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      if (!role) this.contacts = res.data;
      else if (role === "Tenant") this.tenants = res.data;
      else if (role === "Owner") this.owners = res.data;
      else if (role === "BM") this.bms = res.data;
      else this.contractors = res.data;
      console.log("Contact", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };


  getContactById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Contact/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      this.contactInfo = res.data;
      console.log("Contact", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  getBMById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Bm/Contact/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      this.contactInfo = res.data;
      console.log("BM", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };


  deleteContactById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Contact/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateContact = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Contact?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.contactInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createContact = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Contact?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.contactInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  getContactAttachment = async (id: number) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/Contact/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  searchTenant = async (searchTerm: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Contact/Search/Tenant/0/${PAGE_SIZE}?searchTerm=${searchTerm}&platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

}
