import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { AttachmentType, ContactType, PlatformType, UploadSource, VmList } from '../constants/options';
import { FAxios } from '../utilities/file-network';
import { GetUserProfile } from '../utilities/general';
export default class SyncStore {

  loading: boolean = false;

  syncingBM: boolean = false;
  syncingContractor: boolean = false;
  syncingEntity: boolean = false;
  syncingProperty: boolean = false;
  syncingStaff: boolean = false;
  loadingList: boolean = false;

  entityList: VmList ={ totalCount: 0, items: []};

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      syncBMData: observable,
      syncContractorData: observable,
      syncEntityData: observable,
      syncingEntity: observable,
      syncingStaff: observable,
      entityList: observable,
      loadingList: observable,
    });
    this.rootStore = rootStore;
  }

  getSyncSummary = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Agent/Company/Sync/0/0?agentCompanyId=${GetUserProfile().agentCompanyId}&platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  syncBMData = async () => {
    this.syncingBM = true;
    try {
      const res = await Axios.get(`/api/DataSync/iRental/BM?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.syncingBM = false;
    }
  };

  syncContractorData = async () => {
    this.syncingContractor = true;
    try {
      const res = await Axios.get(`/api/DataSync/iRental/Contractor?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.syncingContractor = false;
    }
  };

  syncEntityData = async () => {
    this.syncingEntity = true;
    try {
      const res = await Axios.get(`/api/DataSync/iRental/Entity?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.syncingEntity = false;
    }
  };

  syncPropertyData = async () => {
    this.syncingProperty = true;
    try {
      const res = await Axios.get(`/api/DataSync/iRental/Property?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.syncingProperty = false;
    }
  };

  syncStaffData = async () => {
    this.syncingStaff = true;
    try {
      const res = await Axios.get(`/api/DataSync/iRental/Staff?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.syncingStaff = false;
    }
  };

  syncCaseData = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/DataSync/iRental/Case/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getSyncedEntityData = async (skip: number, take: number, searchTerm: string = "") => {
    this.loadingList = true;
    try {
      const res = await Axios.get(`/api/Contact/Search/iRental/${skip}/${take}?searchTerm=${searchTerm}&platformId=${PlatformType.AGENT_ADMIN}`);
      this.entityList = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingList = false;
    }
  };

}
