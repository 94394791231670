import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { AttachmentType, PAGE_SIZE, PlatformType, UploadSource, VmList } from '../constants/options';
import { FAxios } from '../utilities/file-network';
import { GetUserProfile } from '../utilities/general';
export default class OutgoingStore {

  loading: boolean = false;

  searching: boolean = false;

  loadingInfo: boolean = false;

  downloading: boolean = false;

  uploading: boolean = false;

  uploadingAtt: boolean = false;

  deleting: boolean = false;

  recoveries: VmList = { totalCount: 0, items: [] };

  outgoingInfo: any = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      searching: observable,
      loadingInfo: observable,
      downloading: observable,
      uploading: observable,
      uploadingAtt: observable,
      deleting: observable,
      recoveries: observable,
      outgoingInfo: observable,
    });
    this.rootStore = rootStore;
  }

  searchOutgoingRecovery = async (skip: number, take: number, reqModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/OutgoingRecovery/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.recoveries = res.data;
      console.log("Search Outgoing Recovery", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  createOutgoingRecovery = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/OutgoingRecovery?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Create Outgoing Recovery", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  updateOutgoingRecovery = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/MaintenanceRequest/OutgoingRecovery?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Update Outgoing Recovery", res.data);
      this.outgoingInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  getOutgoingRecovery = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/MaintenanceRequest/OutgoingRecovery/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Outgoing Recovery Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  searchPMO = async (searchTerm: string, skip: number, take: number = PAGE_SIZE) => {
    this.searching = true;
    try {
      const res = await Axios.get(`/api/Contact/Search/PMO/${skip}/${take}?searchTerm=${searchTerm}&platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Search PMO", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.searching = false;
    }
  };

  searchtOutgoingRecoveryAttachments = async (outgoingRecoveryId: number) => {
    this.downloading = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/OutgoingRecovery/Attachment/Search/0/0?platformId=${PlatformType.AGENT_ADMIN}`, { agentCompanyId: GetUserProfile().agentCompanyId, outgoingRecoveryId });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  getOutgoingRecoveryAttachment = async (savedName: string) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/MaintenanceRequest/OutgoingRecovery/Attachment/${savedName}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  uploadOutgoingRecoveryAttachment = async (id: number, files: any[], type: "Image" | "Attachment") => {
    if (type === "Image") this.uploading = true;
    else this.uploadingAtt = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/OutgoingRecovery/Attachment?outgoingRecoveryId=${id}&platformId=${PlatformType.AGENT_ADMIN}`, files);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      if (type === "Image") this.uploading = false;
      else this.uploadingAtt = false;
    }
  };

  deleteOutgoingRecoveryAttachment = async (id: number) => {
    this.deleting = true;
    try {
      const res = await Axios.delete(`/api/MaintenanceRequest/OutgoingRecovery/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleting = false;
    }
  };

  resendConversationEmail = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/MaintenanceRequest/OutgoingRecovery/ConversationSummary/Resend/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  claimDecision = async (outgoingRecoveryId: number, convertItems: any[]) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/MaintenanceRequest/OutgoingRecovery/Claim/Decision?platformId=${PlatformType.AGENT_ADMIN}`, { outgoingRecoveryId, convertItems });
      console.log("Claim decision", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };
}
