exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-building-manager-index-tsx": () => import("./../../../src/pages/building-manager/index.tsx" /* webpackChunkName: "component---src-pages-building-manager-index-tsx" */),
  "component---src-pages-contractor-index-tsx": () => import("./../../../src/pages/contractor/index.tsx" /* webpackChunkName: "component---src-pages-contractor-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-email-group-approved-emails-tsx": () => import("./../../../src/pages/email-group/approved-emails.tsx" /* webpackChunkName: "component---src-pages-email-group-approved-emails-tsx" */),
  "component---src-pages-email-group-pending-emails-tsx": () => import("./../../../src/pages/email-group/pending-emails.tsx" /* webpackChunkName: "component---src-pages-email-group-pending-emails-tsx" */),
  "component---src-pages-forget-password-tsx": () => import("./../../../src/pages/forget-password.tsx" /* webpackChunkName: "component---src-pages-forget-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maintenance-index-tsx": () => import("./../../../src/pages/maintenance/index.tsx" /* webpackChunkName: "component---src-pages-maintenance-index-tsx" */),
  "component---src-pages-maintenance-list-tsx": () => import("./../../../src/pages/maintenance/list.tsx" /* webpackChunkName: "component---src-pages-maintenance-list-tsx" */),
  "component---src-pages-maintenance-outgoing-recovery-tsx": () => import("./../../../src/pages/maintenance/outgoing-recovery.tsx" /* webpackChunkName: "component---src-pages-maintenance-outgoing-recovery-tsx" */),
  "component---src-pages-maintenance-ticket-system-tsx": () => import("./../../../src/pages/maintenance/ticket-system.tsx" /* webpackChunkName: "component---src-pages-maintenance-ticket-system-tsx" */),
  "component---src-pages-owner-index-tsx": () => import("./../../../src/pages/owner/index.tsx" /* webpackChunkName: "component---src-pages-owner-index-tsx" */),
  "component---src-pages-property-index-tsx": () => import("./../../../src/pages/property/index.tsx" /* webpackChunkName: "component---src-pages-property-index-tsx" */),
  "component---src-pages-property-manager-index-tsx": () => import("./../../../src/pages/property-manager/index.tsx" /* webpackChunkName: "component---src-pages-property-manager-index-tsx" */),
  "component---src-pages-summary-index-tsx": () => import("./../../../src/pages/summary/index.tsx" /* webpackChunkName: "component---src-pages-summary-index-tsx" */),
  "component---src-pages-system-setting-index-tsx": () => import("./../../../src/pages/system-setting/index.tsx" /* webpackChunkName: "component---src-pages-system-setting-index-tsx" */),
  "component---src-pages-tenant-index-tsx": () => import("./../../../src/pages/tenant/index.tsx" /* webpackChunkName: "component---src-pages-tenant-index-tsx" */)
}

