import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { AttachmentType, PlatformType, UploadSource, VmList } from '../constants/options';
import { FAxios } from '../utilities/file-network';
export default class EmailStore {

  loading: boolean = false;

  loadingGroup: boolean = false;

  loadingSearchGroup: boolean = false;

  loadingInfo: boolean = false;

  loadingGroupInfo: boolean = false;

  loadingMore: boolean = false;

  sendingEmail: boolean = false;

  updatingGroup: number = 0;

  loadingEmailInGroup: boolean = false;

  loadingComment: boolean = false;

  emailList: VmList = { items: [], totalCount: 0 };

  groupList: VmList = { items: [], totalCount: 0 };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingGroup: observable,
      loadingSearchGroup: observable,
      loadingInfo: observable,
      loadingGroupInfo: observable,
      loadingMore: observable,
      updatingGroup: observable,
      loadingEmailInGroup: observable,
      loadingComment: observable,
      emailList: observable,
      groupList: observable,
    });
    this.rootStore = rootStore;
  }

  searchEmailList = async (skip: number, take: number, reqModel: any = {}, loadMore?: boolean) => {
    if (loadMore) this.loadingMore = true;
    else this.loading = true;
    try {
      const res = await Axios.post(`/api/Email/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.emailList = res.data;
      console.log("Email List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      this.loadingMore = false;
    }
  };

  getEmailById = async (id: number, inGroupLoading?: boolean) => {
    if (inGroupLoading) this.loadingEmailInGroup = true;
    else this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Email/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Email Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      if (inGroupLoading) this.loadingEmailInGroup = false;
      else this.loadingInfo = false;
    }
  };

  updateEmail = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Email?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Update Email Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  syncEmail = async (id: number) => {
    // this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Email/Sync/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Sync Data", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      // this.loadingInfo = false;
    }
  };

  createEmailGroup = async (reqModel: any) => {
    this.loadingGroup = true;
    try {
      const res = await Axios.post(`/api/Email/Group?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Create Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingGroup = false;
    }
  };

  searchEmailGroup = async (skip: number, take: number, reqModel: any = {}, loadSearch?: boolean) => {
    if (!loadSearch) this.loadingGroup = true;
    else this.loadingSearchGroup = true;
    try {
      const res = await Axios.post(`/api/Email/Group/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.groupList = res.data;
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      if (!loadSearch) this.loadingGroup = false;
      else this.loadingSearchGroup = false;
    }
  };

  updateEmailGroup = async (reqModel: any) => {
    this.updatingGroup = reqModel.id;
    try {
      const res = await Axios.put(`/api/Email/Group?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Update Email Group Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updatingGroup = 0;
    }
  };

  getEmailGroupById = async (skip: number, take: number, groupId: number) => {
    this.loadingGroupInfo = true;
    try {
      const res = await Axios.post(`/api/Email/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, { groupId });
      // this.emailList = res.data;
      console.log("Email List By Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingGroupInfo = false;
    }
  };

  getSingleEmailGroupById = async (groupId: number) => {
    this.loadingComment = true;
    try {
      const res = await Axios.get(`/api/Email/Group/${groupId}?platformId=${PlatformType.AGENT_ADMIN}`);
      // this.emailList = res.data;
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingComment = false;
    }
  };

  deleteEmailGroup = async (groupId: number) => {
    this.updatingGroup = groupId;
    try {
      const res = await Axios.delete(`/api/Email/Group/${groupId}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updatingGroup = 0;
    }
  };

  moveEmailsToGroup = async (groupId: number, emailList: number[]) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Email/Group/Move/Emails?groupId=${groupId}&platformId=${PlatformType.AGENT_ADMIN}`, emailList);
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  assignMRToEmail = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Email/Assign/MaintenanceRequest?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  assignCaseToEmail = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Email/Assign/Case?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  sendEmailToEPM = async (reqModel: any) => {
    this.sendingEmail = true;
    try {
      const res = await Axios.post(`/api/AI/ePM/MaintenanceRequest?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Email responded", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.sendingEmail = false;
    }
  };

  createEPMLog = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/AI/ePM/Log?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  approveMaintenanceEmail = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Email/Approve/MaintenanceRequest?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  rejectMaintenanceEmail = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Email/Reject/MaintenanceRequest?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

}
