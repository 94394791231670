import {
  computed, observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { isWeb } from '../utilities/platform';
import RootStore from './root-store';
import { navigate } from 'gatsby';
import { PAGE_SIZE, PlatformType, UserInfo, VmList } from '../constants/options';
import { GetLocalStorage, SetLocalStorage } from '../utilities/cryptography';
import { AREA_CATEGORY, USER_INFO } from '../constants/storage-keys';
import { GetUserProfile } from '../utilities/general';

export default class UserStore {
  get isAuthed() {
    return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null;
  }

  set isAuthed(value) {
    console.log('Deleting token...');
    if (!value && isWeb) localStorage.clear();
  }

  loading: boolean = false;

  uploading: boolean = false;

  loadingAccount: boolean = false;

  signingIn: boolean = false;

  userToken: string | null = null;

  agentCompanyInfo: any = null;

  emailAccounts: VmList = { totalCount: 0, items: [] };

  switchingUrgent: number[] = [];

  versionNumber: string = "";

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      isAuthed: computed,
      loading: observable,
      uploading: observable,
      loadingAccount: observable,
      signingIn: observable,
      userToken: observable,
      agentCompanyInfo: observable,
      emailAccounts: observable,
      switchingUrgent: observable,
      versionNumber: observable,
    });
    this.rootStore = rootStore;
  }


  signIn = async (userModel: any) => {
    this.signingIn = true;
    try {
      const res = await Axios.post(`/api/Auth/SignIn?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      const token = `Bearer ${res.data.token}`;
      const user = res.data;
      this.userToken = user.token;
      if (isWeb) {
        localStorage.USER_TOKEN = token;
        let userInfo: UserInfo = {
          id: user.contact.id,
          username: user.username,
          firstName: user.contact.firstName,
          lastName: user.contact.lastName,
          email: user.email,
          agentCompanyId: user.contact.contactAgents[0].agentCompanyId,
          agentCompanyIds: user.contact.contactAgents.map((a: any) => a.agentCompanyId),
          logoId: user.contact.agentCompanyContacts[0].agentCompany.logoId,
          pms: user.contact.pc,
        };
        SetLocalStorage(USER_INFO, userInfo);
      }
      console.log("User Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      this.isAuthed = false;
      return Promise.reject(err);
    } finally {
      this.signingIn = false;
    }
  };

  signOut = () => {
    this.isAuthed = false;
    this.userToken = null;
    navigate('/');
    this.rootStore.notify('Bye', "success");
  };

  signUp = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/SignUp?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  refreshToken = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/Refresh/Token?platformId=${PlatformType.AGENT_ADMIN}`);
      localStorage.USER_TOKEN = `Bearer ${res.data.token}`;
      let info = GetLocalStorage(USER_INFO);
      if (info) SetLocalStorage(USER_INFO, { ...info, pms: res.data.pc });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  changePassword = async (userModel: { oldPassword: string, newPassword: string; }) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/Password/Change?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  forgetPassword = async (username: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Auth/Password/Forget?username=${username}&platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  forgetPasswordCallback = async (userModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Auth/Password/Forget/Callback?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getAgentCompanyInfo = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Agent/Company?platformId=${PlatformType.AGENT_ADMIN}`);
      this.agentCompanyInfo = res.data;
      if (GetLocalStorage(USER_INFO)) {
        let userInfo = GetLocalStorage(USER_INFO);
        userInfo.logoId = res.data.logoId;
        SetLocalStorage(USER_INFO, userInfo);
      }
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  createAgentEmailAccount = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Agent/Email?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Add Email Account", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateAgentEmailAccount = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Agent/Email?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  deleteAgentEmailAccount = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Agent/Email/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchAgentEmailAccounts = async (skip: number, take: number, reqModel: any = { agentCompanyId: GetUserProfile().agentCompanyId }) => {
    this.loadingAccount = true;
    try {
      const res = await Axios.post(`/api/Agent/Email/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.emailAccounts = res.data;
      console.log("Email Accounts", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingAccount = false;
    }
  };

  updateAgentCompanyInfo = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Agent/Company?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  uploadAgentLogo = async (reqModel: any) => {
    this.uploading = true;
    try {
      const res = await Axios.post(`/api/Agent/Company/Attachment/Logo?agentCompanyId=${GetUserProfile().agentCompanyId}&platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      if (GetLocalStorage(USER_INFO)) {
        let userInfo = GetLocalStorage(USER_INFO);
        userInfo.logoId = res.data.id;
        SetLocalStorage(USER_INFO, userInfo);
      }
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  setAgentAreaSetting = async (reqModel: { id: number, isUrgent: boolean, parentId: number; }) => {
    this.switchingUrgent.push(reqModel.id);
    try {
      const res = await Axios.post(`/api/Agent/Area/Category/Setting/Set?platformId=${PlatformType.AGENT_ADMIN}`, { areaCategoryId: reqModel.id, isUrgent: reqModel.isUrgent, agentCompanyId: GetUserProfile().agentCompanyId });
      if (GetLocalStorage(AREA_CATEGORY)) {
        let areaCategory = GetLocalStorage(AREA_CATEGORY);
        let parentIndex = areaCategory.map((a: any) => a.id).indexOf(reqModel.parentId);
        if (parentIndex >= 0) {
          let targetChild = areaCategory[parentIndex].children.find((c: any) => c.id == reqModel.id);
          if (targetChild) {
            targetChild.isUrgent = reqModel.isUrgent;
            areaCategory[parentIndex].children = areaCategory[parentIndex].children.filter((c: any) => c.id !== reqModel.id);
            areaCategory[parentIndex].children.push(targetChild);
            areaCategory[parentIndex].children = areaCategory[parentIndex].children.sort((a: any, b: any) => a.name.localeCompare(b.name));
            SetLocalStorage(AREA_CATEGORY, areaCategory);
          }
        }
      }
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.switchingUrgent.splice(this.switchingUrgent.indexOf(reqModel.id), 1);
    }
  };


  getVersion = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Version?platformId=${PlatformType.AGENT_ADMIN}`);
      this.versionNumber = res.data.version;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };
}
