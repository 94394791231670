export const USER_INFO = "USER_INFO";
export const AREA_ITEM_GROUP = "AREA_ITEM_GROUP";
export const AREA_CATEGORY = "AREA_CATEGORY";
export const JOB_CATEGORY = "JOB_CATEGORY";
export const PROPERTY_GROUP_CATEGORY = "PROPERTY_GROUP_CATEGORY";
export const PROPERTY_STATUS = "PROPERTY_STATUS";
export const APARTMENT_STATUS_OPTIONS = "APARTMENT_STATUS_OPTIONS";
export const RESIDENT_TYPE_OPTIONS = "RESIDENT_TYPE_OPTIONS";
export const APARTMENT_LEVEL_OPTIONS = "APARTMENT_LEVEL_OPTIONS";
export const CASE_STATUS = "CASE_STATUS";
export const APARTMENT_LEVEL = "APARTMENT_LEVEL";
export const APARTMENT_STATUS = "APARTMENT_STATUS";
export const APARTMENT_OWNER_CATEGORY = "APARTMENT_OWNER_CATEGORY";
export const APARTMENT_RESIDENT_CATEGORY = "APARTMENT_RESIDENT_CATEGORY";
export const FIRST_ENTER_SCREEN = "FIRST_ENTER_SCREEN";
