import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { AttachmentType, ContactType, PlatformType, UploadSource, VmList } from '../constants/options';
import { FAxios } from '../utilities/file-network';
import { GetUserProfile } from '../utilities/general';
export default class PermissionStore {

  loading: boolean = false;

  deleting: boolean = false;

  loadingInfo: boolean = false;

  loadingAccount: boolean = false;
  processing: boolean = false;
  loadingAccountInfo: boolean = false;
  accountInfo: any = null;
  accountList: VmList = { totalCount: 0, items: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      deleting: observable,
      loadingInfo: observable,
      loadingAccount: observable,
      processing: observable,
      loadingAccountInfo: observable,
      accountList: observable,
    });
    this.rootStore = rootStore;
  }

  signUp = async (reqModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.post(`/api/Auth/SignUp?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };

  searchRoles = async (skip: number, take: number, reqModel: any = { agentCompanyId: GetUserProfile().agentCompanyId }) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Agent/Role/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Search Role", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  createRole = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Agent/Role?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateRole = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.put(`/api/Agent/Role?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  removeRole = async (id: number) => {
    this.deleting = true;
    try {
      const res = await Axios.delete(`/api/Agent/Role/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleting = false;
    }
  };

  searchAgentAccounts = async (skip: number, take: number, reqModel: any = { categoryIds: [ContactType.PM], hazardAccount: true, agentCompanyId: GetUserProfile().agentCompanyId }) => {
    this.loadingAccount = true;
    try {
      const res = await Axios.post(`/api/Contact/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}&sRemoveObj=properties,propertyOwners,propertyTenants,jobSummary,attachments,categoryIds,coveredAreas`, reqModel);
      this.accountList = res.data;
      console.log("Search Agent Accounts", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingAccount = false;
    }
  };

  getAgentAccount = async (id: number) => {
    this.loadingAccountInfo = true;
    try {
      const res = await Axios.get(`/api/Contact/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingAccountInfo = false;
    }
  };


  createAgentAccount = async (reqModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.post(`/api/Auth/SignUp?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };

  updateAgentAccount = async (reqModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.put(`/api/Contact?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };

  removeAgentAccount = async (id: number) => {
    this.loadingAccount = true;
    try {
      const res = await Axios.delete(`/api/Contact/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingAccount = false;
    }
  };

  assignRoleToAccount = async (reqModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.post(`/api/Agent/Role/Assign?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };
}
