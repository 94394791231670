import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { AttachmentType, PlatformType, UploadSource, VmList } from '../constants/options';
import { FAxios } from '../utilities/file-network';
export default class TicketStore {

  loading: boolean = false;

  loadingInfo: boolean = false;

  loadingSearch: boolean = false;
  
  updating: number = 0;

  emailList: VmList = { items: [], totalCount: 0 };

  tickets: VmList = { items: [], totalCount: 0 };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingInfo: observable,
      loadingSearch: observable,
      updating: observable,
      emailList: observable,
      tickets: observable,
    });
    this.rootStore = rootStore;
  }

  createTicket = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Ticket?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Create Ticket", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchTicket = async (skip: number, take: number, reqModel: any = {}, loadSearch?: boolean) => {
    if (!loadSearch) this.loading = true;
    else this.loadingSearch = true;
    try {
      const res = await Axios.post(`/api/Ticket/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.tickets = res.data;
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      if (!loadSearch) this.loading = false;
      else this.loadingSearch = false;
    }
  };

  updateTicket = async (reqModel: any) => {
    this.updating = reqModel.id;
    try {
      const res = await Axios.put(`/api/Ticket?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Update Ticket", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updating = 0;
    }
  };

  getTicketById = async (ticketId: number) => {
    this.updating = ticketId;
    try {
      const res = await Axios.get(`/api/Ticket/${ticketId}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Ticket Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updating = 0;
    }
  };

  deleteTicketById = async (ticketId: number) => {
    this.updating = ticketId;
    try {
      const res = await Axios.delete(`/api/Ticket/${ticketId}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Delete Ticket", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updating = 0;
    }
  };

  addEmailsToTicket = async (ticketId: number, emailIds: number[]) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Ticket/Email/Add?platformId=${PlatformType.AGENT_ADMIN}`, { ticketId, emailIds });
      console.log("Add Email to Ticket", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  moveEmailsToGroup = async (groupId: number, emailList: number[]) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Email/Group/Move/Emails?groupId=${groupId}&platformId=${PlatformType.AGENT_ADMIN}`, emailList);
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  removeEmailsFromGroup = async (ticketId: number, emailIds: number[]) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Ticket/Email/Remove?platformId=${PlatformType.AGENT_ADMIN}`, { ticketId, emailIds });
      console.log("Email Group", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };



  addEmailToTickets = async (ticketIds: number[], emailId: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Email/Ticket/Add?platformId=${PlatformType.AGENT_ADMIN}`, { ticketIds, emailId });
      console.log("Add Ticket to Emails ", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  }

}
