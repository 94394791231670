import { OverridableComponent } from "@mui/material/OverridableComponent";
import useStores from "../hooks/use-stores";
import { SvgIconTypeMap } from "@mui/material";

export const PAGE_SIZE = 10;

export const DESKTOP_SIZE = 1536;

export const DEFAULT_COLOUR_INDICATOR = "#9ca3af";

export const IRENTAL_ERR_CODE = 600;

export enum Language { ENGLISH = 'en-AU', ZH_CN = 'zh-CN', ZH_TW = 'zh-TW', ZH_HK = 'zh-HK' };

export interface VmList { totalCount: number, items: any[]; };

export interface VmOption { id: number, label: string | number, value?: any; };
export interface VmTab { key: string | number, label: string | number; };

export enum ColorScheme { INITIAL, ERROR, WARNING, SUCCESS, INFO };

export const LATEST_AREA_VER = 2;
// ================ VM Dynamic Fields ================ 
export enum FieldType { TEXT, RICH_TEXT, NUMBER, TEXT_AREA, DATE, DATE_TIME, TIME, SELECT, CHECKBOX, RADIO, EMPTY };

export interface VmDynamicField {
  id?: number,
  fieldType: FieldType,
  name?: string,
  placeHolder?: string,
  value?: string,
  colSpan?: number,
  rows?: number,
  // selections only for select & radio buttons
  selections?: VmOption[],
  selectionsCols?: number,
  error?: boolean,
  autoWidth?: boolean,
  disabled?: boolean,
  minRows?: number,
  onlyShow?: boolean,
}
// ================ End VM Dynamic Fields ================ 
// ================  VM Smart Table ================ 
export interface VmTBody {
  type: VmTBodyType,
  colorScheme?: ColorScheme,
  isEnable?: boolean,
  fields: string[] | VmTableButton[] | {
    compareBy: any,
    compareTo: any,
    operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&",

    true: string[] | VmTableButton[] | {
      compareBy: any,
      compareTo: any,
      operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&",
      true: string[],
      false: string[],
      enableIndicator: boolean,
    };
    false: string[] | VmTableButton[] | {
      compareBy: any,
      compareTo: any,
      operator: "||" | ">" | ">=" | "<" | "<=" | "==" | "&&",
      true: string[],
      false: string[],
      enableIndicator: boolean,
    };
  } | {
    fields: string[],
    onClick: (req: any, event: any) => any,
  };
};
export enum VmTBodyType {
  NO,
  TEXT,
  NUMBER,
  COMPLEX,
  DESCRIPTION,
  CURRENCY,
  DATE,
  DATE_TIME,
  TIME,
  BUTTON,
  TEXT_LINK,
  TEXT_BUTTON,
  STATUS,
  CONDITION,
  URGENT,
  PRIORITY,
  IMAGES,
  CHIP_LIST,
  MULTILINE,
  VERFIY_STAGE,
  CASE_DATE_TIME,
  CONTRACTOR_NAME,
  OUTGOING_STATUS,
  CASE_TYPE
};
export interface VmTableSearch { searchFields: string[], title: string, placeHolder?: string; }
export interface VmTableButton {
  label: string,
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> | any,
  onClick: (req: any, event: any) => any,
  colorScheme?: ColorScheme,
};
// ================ End VM Smart Table ================ 

export interface MultiLangField {
  enField: string,
  cnField: string,
  hkField: string,
  twField: string,
}
export const LangList = [Language.ENGLISH, Language.ZH_CN, Language.ZH_HK, Language.ZH_TW];

export const States = ["NSW", "ACT", "NT", "QLD", "SA", "VIC", "TAS", "WA"];

/**
 * User base information
 */
export interface UserInfo {
  id: number,
  username: string,
  firstName: string,
  lastName: string,
  email?: string,
  gender?: string,
  country?: string,
  address?: string,
  phone?: string,
  state?: string,
  suburb?: string,
  agentCompanyId?: number,
  agentCompanyIds?: number[],
  logoId?: number | any,
  pms: any[],
}
/**
 * Attachment Type
 * Use this type when uploading files
 * Therefore backend know which type of file is uploading
 */
export enum AttachmentType {
  IMAGE = 1,
  DOC = 2,
  INVOICE = 5,
  SIGNATURE = 11,
  VIDEO = 12,
  CERTIFICATE = 13,
  LOGO = 15,
}

export enum ImageSource { MAINTENANCE_REQUEST, CASE, JOB_ORDER, VISIT, LOGO, INVOICE, EMAIL, CASE_QUOTE, OUTGOING_RECOVERY };

/**
 * Use for component swiper
 */
export interface Image {
  id: number,
  localUrl?: string,
  formData?: UploadSource,
}

/**
 * When uploading file, we need to send this the request body in respecting to structure of this interface
 * Filename is the exact name of the file u are trying to upload with
 * Expiry date - only use for insurance file
 * Actual file, it is a BASE64 STRING! Convert it to BASE64 before uploading
 */
export interface UploadSource {
  fileName: string,
  file: string,
  attachmentCategoryId: AttachmentType,
}

/**
 * Case stages, each stage has different status
 */
export enum CaseStage {
  NONE = 0,
  NEW = 1,
  ASSESS = 2,
  QUOTATIONS = 3,
  OWNER_APPROVAL = 4,
  JOB_ORDER = 5,
  VERIFY = 6,
  INVOICE = 7,
  COMPLETED = 8,
  ARCHIVED = 9,
  IN_POOL = 1000,
}

/**
 * Contact role
 */
export enum ContactType {
  ADMIN = 1,
  PM_ADMIN = 2,
  BM = 3,
  OWNER = 4,
  TENANT = 5,
  PM = 6,
  CONTRACTOR = 7,
  EMERGENCY = 8,
  SM = 9,
}

/**
 * Platform 
 */
export enum PlatformType {
  PLATFORM = 1,
  TENANT_WEB = 2,
  TENANT_APP = 3,
  CONTRACTOR_WEB = 4,
  CONTRACTOR_APP = 5,
  PIA = 7,
  AGENT_ADMIN = 8,
}

/**
 * Property 
 */
export enum PropertyStatus {
  OCCUPIED = 1,
  VACANT = 2,
  SELLING = 3,
  RENTING = 4,
  DEPOSIT_TAKEN = 5,
  RENOVATION = 6,
}

/**
 * Email
 */
export enum EmailType {
  RECIEVED_EMAIL = 7,
  RECIEVED_MAINTENANCE_EMAIL = 10,
  APPROVED_EMAILS = 11,
}


/**
 * Quote expired days 
 */
export const EXPIRED_DAYS_OPTION = [30, 60, 90];
