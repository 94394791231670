
import { observable, action, makeObservable } from 'mobx';
import UserStore from './user-store';
import MaintenanceStore from './maintenance-store';
import OptionStore from './option-store';
import ContactStore from './contact-store';
import PropertyStore from './property-store';
import SummaryStore from './summary-store';
import EmailStore from './email-store';
import PermissionStore from './permission-store';
import SyncStore from './sync-store';
import OutgoingStore from './outgoing-store';
import TicketStore from './ticket-store';
interface WebNotification {
  message: string;
  options?: {
    variant: "success" | "error" | "info" | "warning";
  };
}

export default class RootStore {
  testVal = "TEST_VALUE";

  destination = "Select";

  notification: WebNotification = {
    message: "",
    options: {
      variant: "info",
    },
  };

  userStore;

  optionStore;

  maintenanceStore;

  contactStore;

  propertyStore;

  summaryStore;

  emailStore;

  permissionStore;

  syncStore;

  outgoingStore;

  ticketStore;

  constructor() {
    makeObservable(this, {
      testVal: observable,
      destination: observable,
      notification: observable,
      notify: action,
    });
    this.userStore = new UserStore(this);
    this.optionStore = new OptionStore(this);
    this.maintenanceStore = new MaintenanceStore(this);
    this.contactStore = new ContactStore(this);
    this.propertyStore = new PropertyStore(this);
    this.summaryStore = new SummaryStore(this);
    this.emailStore = new EmailStore(this);
    this.permissionStore = new PermissionStore(this);
    this.syncStore = new SyncStore(this);
    this.outgoingStore = new OutgoingStore(this);
    this.ticketStore = new TicketStore(this);
  }

  notify(msg: string, level?: "success" | "error" | "info" | "warning") {
    if (level) {
      this.notification = {
        message: msg,
        options: {
          variant: level,
        },
      };
    } else {
      this.notification = {
        message: msg,
      };
    }
  }
}
