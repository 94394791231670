import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { PlatformType, VmList } from '../constants/options';
import { SetLocalStorage } from '../utilities/cryptography';
import { AREA_CATEGORY, AREA_ITEM_GROUP, JOB_CATEGORY } from '../constants/storage-keys';
import { GetUserProfile } from '../utilities/general';

export default class OptionStore {

  loading: boolean = false;

  processing: boolean = false;

  areaCategories: VmList = { totalCount: 0, items: [] };

  areaCategoryInfo: any = null;

  jobCategories: VmList = { totalCount: 0, items: [] };

  jobCategoryInfo: any = null;

  contacts: VmList = { totalCount: 0, items: [] };

  suburbs: VmList = { totalCount: 0, items: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      processing: observable,
      areaCategories: observable,
      areaCategoryInfo: observable,
      jobCategories: observable,
      jobCategoryInfo: observable,
      contacts: observable,
      suburbs: observable,
    });
    this.rootStore = rootStore;
  }


  searchAreaCategories = async (skip: number, take: number, userModel: any = { agentCompanyId: GetUserProfile().agentCompanyId }) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Area/Category/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.areaCategories = res.data;
      let areaList: any[] = [];
      res.data.items.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((area: any) => {
        let parentObj = {
          ...area,
          children: area.children.sort((a: any, b: any) => a.name.localeCompare(b.name))
        };
        areaList.push(parentObj);
      });
      SetLocalStorage(AREA_CATEGORY, areaList);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchAreaItemGroups = async (userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Area/Category/Group/Search/0/0?platformId=${PlatformType.TENANT_WEB}`, userModel);
      SetLocalStorage(AREA_ITEM_GROUP, res.data.items);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };
  
  createAreaCategory = async (userModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.post(`/api/Area/Category?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.areaCategoryInfo = res.data;
      // console.log("create areaCategories", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };

  updateAreaCategory = async (userModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.put(`/api/Area/Category?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.areaCategoryInfo = res.data;
      // console.log("update serviceCategories", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };


  deleteAreaCategory = async (id: number) => {
    this.processing = true;
    try {
      const res = await Axios.delete(`/api/Area/Category/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };

  searchJobCategories = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Job/Category/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.jobCategories = res.data;
      let jobList: any[] = [];
      res.data.items.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((job: any) => {
        let parentObj = {
          ...job,
          children: job.children.sort((a: any, b: any) => a.name.localeCompare(b.name))
        };
        jobList.push(parentObj);
      });
      SetLocalStorage(JOB_CATEGORY, jobList);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  createJobCategory = async (userModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.post(`/api/Job/Category?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.jobCategoryInfo = res.data;
      // console.log("create serviceCategories", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };

  updateJobCategory = async (userModel: any) => {
    this.processing = true;
    try {
      const res = await Axios.put(`/api/Job/Category?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.jobCategoryInfo = res.data;
      // console.log("update serviceCategories", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };


  deleteJobCategory = async (id: number) => {
    this.processing = true;
    try {
      const res = await Axios.delete(`/api/Job/Category/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.processing = false;
    }
  };
  getSuburbList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Country/State/Suburb/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.suburbs = res.data;
      console.log("Suburb", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

}
