import {
  computed, observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { isWeb } from '../utilities/platform';
import RootStore from './root-store';
import { navigate } from 'gatsby';
import { AttachmentType, PAGE_SIZE, PlatformType, UploadSource, UserInfo, VmList } from '../constants/options';
import { SetLocalStorage } from '../utilities/cryptography';
import { USER_INFO } from '../constants/storage-keys';
import { FAxios } from '../utilities/file-network';
import { Priority } from '../utilities/maintenance';

export default class MaintenanceStore {

  loading: boolean = false;

  loadingArchive: boolean = false;

  loadingInfo: boolean = false;

  loadingQuote: boolean = false;

  uploading: boolean = false;

  uploadingImg: boolean = false;

  uploadingAtt: boolean = false;

  downloading: boolean = false;
  deletingFile: boolean = false;
  updatingPriority: boolean = false;

  loadingLog: boolean = false;

  requests: VmList = { totalCount: 0, items: [] };
  requestInfo: any = null;

  cases: VmList = { totalCount: 0, items: [] };
  caseInfo: any = null;

  jobOrders: VmList = { totalCount: 0, items: [] };

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingArchive: observable,
      loadingInfo: observable,
      loadingQuote: observable,
      uploading: observable,
      deletingFile: observable,
      uploadingImg: observable,
      uploadingAtt: observable,
      updatingPriority: observable,
      downloading: observable,
      loadingLog: observable,
      requests: observable,
      requestInfo: observable,
      cases: observable,
      caseInfo: observable,
      jobOrders: observable,
    });
    this.rootStore = rootStore;
  }

  searchCases = async (skip: number, take: number, userModel: any = {}, isArchive?: boolean) => {
    if (isArchive) this.loadingArchive = true;
    else this.loading = true;
    try {
      const removeObj = "bm,category,requestForContactCategory,requestForContactChildren,createdByContact,lastModifiedByContact,quotes,contractors,jobOrders,adminSelectedQuotes,agentCompany,lastmodifiedbycontact,propertychildren,";
      const res = await Axios.post(`/api/Case/Search/${skip}/${take}?sRemoveObj=${removeObj}&platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.cases = res.data;
      console.log("Cases", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingArchive = false;
      this.loading = false;
    }
  };

  searchCompletedCase = async (skip: number, take: number, reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Case/Search/Completed/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchArchivedCase = async (skip: number, take: number, reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Case/Search/Archived/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getCase = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Case/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      this.caseInfo = res.data;
      console.log("Case info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createCase = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  updateCase = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Case?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  uploadCaseAttachment = async (id: number, files: any[], type: "Image" | "Attachment" | null = null) => {
    if (type === null) {
      this.uploading = true;
    } else {
      if (type === "Image") { this.uploadingImg = true; }
      else { this.uploadingAtt = true; }
    }

    try {
      const res = await Axios.post(`/api/Case/Attachment?caseId=${id}&platformId=${PlatformType.AGENT_ADMIN}`, files);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      if (type === null) {
        this.uploading = false;
      } else {
        if (type === "Image") { this.uploadingImg = false; }
        else { this.uploadingAtt = false; }
      }

    }
  };

  deleteCaseAttachment = async (id: number) => {
    this.deletingFile = true;
    try {
      const res = await Axios.delete(`/api/Case/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deletingFile = false;
    }
  };

  addContractor = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case/Add/Contractor?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  removeContractor = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case/Remove/Contractor?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  replaceContractor = async (caseId: number, reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case/Replace/Contractor?caseId=${caseId}&platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };


  updateCaseStatus = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Case/Status?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  searchJobOrder = async (skip: number, take: number, reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Case/JobOrder/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.jobOrders = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getJobOrder = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Case/JobOrder/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  updateJobOrder = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Case/JobOrder?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  searchRequests = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.requestInfo = res.data;
      console.log("Request", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };


  archiveCase = async (id: number, reasonToArchive: string) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Case/Archive?platformId=${PlatformType.AGENT_ADMIN}`, { caseId: id, isArchive: true, reasonToArchive });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createCaseQuote = async (userModel: any) => {
    this.loadingQuote = true;
    try {
      const res = await Axios.post(`/api/Case/Quote?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      console.log("Quotes", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingQuote = false;
    }
  };

  deleteCaseQuote = async (id: number) => {
    this.loadingQuote = true;
    try {
      const res = await Axios.delete(`/api/Case/Quote/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Delete Quotes", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingQuote = false;
    }
  };

  getRequest = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/MaintenanceRequest/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      this.requestInfo = res.data;
      console.log("Request info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  getCaseAttachment = async (id: number) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/Case/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  getRequestAttachment = async (savedName: string) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/MaintenanceRequest/Attachment/${savedName}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  uploadRequestAttachment = async (id: number, files: any[]) => {
    this.uploading = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/Attachment?maintenanceRequestId=${id}&platformId=${PlatformType.AGENT_ADMIN}`, files);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  deleteRequestAttachment = async (id: number) => {
    this.deletingFile = true;
    try {
      const res = await FAxios.delete(`/api/MaintenanceRequest/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deletingFile = false;
    }
  };

  searchVisit = async (skip: number, take: number, reqModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Case/JobOrder/Visit/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Visit", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getVisitAttachment = async (id: number) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/Case/JobOrder/Visit/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  searchLogs = async (skip: number, take: number, reqModel: any = {}) => {
    this.loadingLog = true;
    try {
      const res = await Axios.post(`/api/Log/Case/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      console.log("Log", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingLog = false;
    }
  };

  // For maintenance dashboard
  getDashboardNewCase = async (reqModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Dashboard/Case/Status/New?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getDashboardCaseStatus = async (categoryId: number, reqModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Dashboard/Case/Status/${categoryId}?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getDashboardArchivedCase = async (reqModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Dashboard/Case/Status/Archived?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getDashboardCompletedCase = async (reqModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Dashboard/Case/Status/Completed?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  createMaintenanceRequest = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createMaintenanceRequestByEmailGroup = async (groupId: number, reqModel = {}) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/MaintenanceRequest/Email/Group?groupId=${groupId}&platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  rejectMaintenanceRequest = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/MaintenanceRequest/Reject?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  sentQuoteEmailToOwner = async (caseId: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case/Quote/Send/${caseId}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  resentQuoteEmailToOwner = async (caseId: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case/Quote/Resend/${caseId}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  forwardEmailToPM = async (caseId: number, email: string) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Case/Quote/Forward/${caseId}?email=${email}&platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  updateCasePriority = async (caseId: number, priority: Priority) => {
    this.updatingPriority = true;
    try {
      const res = await Axios.put(`/api/Case/Priority/${caseId}?priority=${priority}&platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.updatingPriority = false;
    }
  };

  uploadJobOrderAttachment = async (id: number, reqModel: any) => {
    this.uploading = true;
    try {
      const res = await Axios.post(`/api/Case/JobOrder/Attachment?jobOrderId=${id}&platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  generateInvoice = async (jobOrderId: number, isPreview: boolean = true) => {
    this.loadingInfo = true;
    try {
      const res = await FAxios.get(`/api/Case/JobOrder/Invoice/Generate?jobOrderId=${jobOrderId}&isPreview=${isPreview}&platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  generateJobOrderPdf = async (jobOrderId: number, isPreview: boolean = true) => {
    this.loadingInfo = true;
    try {
      const res = await FAxios.get(`/api/Case/JobOrder/PDF/Generate?jobOrderId=${jobOrderId}&isPreview=${isPreview}&platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  getQuoteAttachment = async (fileName: number) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/Case/Quote/Attachment/${fileName}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  uploadQuoteAttachment = async (id: number, reqModel: any) => {
    this.uploadingAtt = true;
    try {
      const res = await Axios.post(`/api/Case/Quote/Attachment?quoteId=${id}&platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploadingAtt = false;
    }
  };

  toggleAttachmentPublic = async (id: number, isRequest?: boolean) => {
    this.uploading = true;
    try {
      const res = await Axios.put(`/api/${isRequest ? "MaintenanceRequest" : "Case"}/Attachment/Toggle/Public/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };
}
