import {
  computed, observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import { isWeb } from '../utilities/platform';
import RootStore from './root-store';
import { navigate } from 'gatsby';
import { PAGE_SIZE, PlatformType, UserInfo, VmList } from '../constants/options';
import { SetLocalStorage } from '../utilities/cryptography';
import { APARTMENT_STATUS_OPTIONS, PROPERTY_GROUP_CATEGORY, PROPERTY_STATUS, RESIDENT_TYPE_OPTIONS, USER_INFO } from '../constants/storage-keys';
import { ConvertListToCommonOptions } from '../utilities/general';
import { FAxios } from '../utilities/file-network';

export default class PropertyStore {

  loading: boolean = false;

  loadingInfo: boolean = false;

  loadingSearch: boolean = false;

  apartments: VmList = { totalCount: 0, items: [] };

  apartmentInfo: any = null;

  groups: VmList = { totalCount: 0, items: [] };

  groupInfo: any = null;

  PMs: VmList = { totalCount: 0, items: [] };


  downloading: boolean = false;

  uploading: boolean = false;

  deletingFile: boolean = false;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingInfo: observable,
      loadingSearch: observable,
      apartments: observable,
      apartmentInfo: observable,
      groups: observable,
      groupInfo: observable,
      PMs: observable,
      downloading: observable,
      uploading: observable,
      deletingFile: observable,

    });
    this.rootStore = rootStore;
  }


  searchApartmentList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.apartments = res.data;
      console.log("Apartment List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getApartmentById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Property/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      this.apartmentInfo = res.data;
      console.log("Property Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createApartment = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateApartment = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Property?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  deleteApartmentById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Property/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchStatusList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Status/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      // this.apartments = res.data;
      // console.log("Apartment List", res.data);
      SetLocalStorage(PROPERTY_STATUS, res.data.items);

      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchGroupCategoryList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Group/Category/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      // this.apartments = res.data;
      // console.log("Apartment List", res.data);
      SetLocalStorage(PROPERTY_GROUP_CATEGORY, res.data.items);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getGroupCategoryById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Property/Group/Category/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      // this.apartmentInfo = res.data;
      // console.log("Apartment Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createGroupCategory = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Group/Category?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateGroupCategory = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Property/Group/Category?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  deleteGroupCategoryById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Property/Group/Category/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchGroupList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Group/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      this.groups = res.data;
      console.log("Group List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getGroupById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Property/Group/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      // this.apartmentInfo = res.data;
      // console.log("Apartment Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createGroup = async (reqModel: any) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Group?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  updateGroup = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Property/Group?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  deleteGroupById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Property/Group/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchOwnerList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Owner/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      // this.apartments = res.data;
      // console.log("Apartment List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getOwnerById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Property/Owner/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      // this.apartmentInfo = res.data;
      // console.log("Apartment Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createOwner = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Property/Owner?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  updateOwner = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Property/Owner?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  deleteOwnerById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Property/Owner/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchTenantList = async (skip: number, take: number, userModel: any = {}) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Tenant/Search/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}`, userModel);
      // this.apartments = res.data;
      // console.log("Apartment List", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getTenantById = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.get(`/api/Property/Tenant/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      // this.apartmentInfo = res.data;
      // console.log("Apartment Info", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  createTenant = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Property/Tenant?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  updateTenant = async (reqModel: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.put(`/api/Property/Tenant?platformId=${PlatformType.AGENT_ADMIN}`, reqModel);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  deleteTenantById = async (id: number) => {
    this.loading = true;
    try {
      const res = await Axios.delete(`/api/Property/Tenant/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  getPropertyAttachment = async (id: number) => {
    this.downloading = true;
    try {
      const res = await FAxios.get(`/api/Property/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`, { responseType: 'blob' });
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.downloading = false;
    }
  };

  uploadPropertyAttachment = async (id: number, file: any) => {
    this.uploading = true;
    try {
      const res = await Axios.post(`/api/Property/Attachment?propertyId=${id}&platformId=${PlatformType.AGENT_ADMIN}`, file);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.uploading = false;
    }
  };

  deletePropertyAttachment = async (id: number) => {
    this.deletingFile = true;
    try {
      const res = await FAxios.delete(`/api/Property/Attachment/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deletingFile = false;
    }
  };

  moveTenant = async (id: number, moveOutDate?: string) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Tenant/Move/Out/${id}?moveOutDate=${moveOutDate ?? ''}&platformId=${PlatformType.AGENT_ADMIN}`);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  moveOwner = async (id: number, moveOutDate?: string) => {
    this.loading = true;
    try {
      const res = await Axios.post(`/api/Property/Owner/Move/Out/${id}?moveOutDate=${moveOutDate ?? ''}&platformId=${PlatformType.AGENT_ADMIN}`);
      // this.apartmentInfo = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  };

  searchPM = async (skip: number, take: number, searchTerm?: any) => {
    this.loadingSearch = true;
    try {

      const res = await Axios.get(`/api/Contact/Search/PM/${skip}/${take}?platformId=${PlatformType.AGENT_ADMIN}${searchTerm ? `&searchTerm=${searchTerm}` : ''}`);
      console.log("Search PM", res.data);
      this.PMs = res.data;
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSearch = false;
    }
  };

  addPM = async (req: any) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.post(`/api/Property/PM?platformId=${PlatformType.AGENT_ADMIN}`, req);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  deletePM = async (id: number) => {
    this.loadingInfo = true;
    try {
      const res = await Axios.delete(`/api/Property/PM/${id}?platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingInfo = false;
    }
  };

  validWarranty = async (propertyId: number, jobCategoryId: number, areaCategoryIds: number[]) => {
    this.loadingSearch = true;
    try {
      const res = await Axios.get(`/api/Property/Warranty?propertyId=${propertyId}&jobCategoryId=${jobCategoryId}&areaCategoryIds=${areaCategoryIds.join(',')}&platformId=${PlatformType.AGENT_ADMIN}`);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingSearch = false;
    }
  };
}
