
import React from 'react';
import { enableStaticRendering } from 'mobx-react';
import RootStore from './root-store';
import UserStore from './user-store';
import OptionStore from './option-store';
import MaintenanceStore from './maintenance-store';
import ContactStore from './contact-store';
import PropertyStore from './property-store';
import SummaryStore from './summary-store';
import EmailStore from './email-store';
import PermissionStore from './permission-store';
import SyncStore from './sync-store';
import OutgoingStore from './outgoing-store';
import TicketStore from './ticket-store';

type CompositeStore = {
  rootStore: RootStore,
  userStore: UserStore,
  optionStore: OptionStore,
  maintenanceStore: MaintenanceStore,
  contactStore: ContactStore,
  propertyStore: PropertyStore,
  summaryStore: SummaryStore,
  emailStore: EmailStore,
  permissionStore: PermissionStore,
  syncStore: SyncStore,
  outgoingStore: OutgoingStore,
  ticketStore: TicketStore,
};

const isServer = typeof window === "undefined";
// eslint-disable-next-line react-hooks/rules-of-hooks
enableStaticRendering(isServer);

// let _stores: null | CompositeStore = null;

const initStores = () => {
  const rootStore = new RootStore();
  const initializedStores: CompositeStore = {
    rootStore,
    userStore: rootStore.userStore,
    optionStore: rootStore.optionStore,
    maintenanceStore: rootStore.maintenanceStore,
    contactStore: rootStore.contactStore,
    propertyStore: rootStore.propertyStore,
    summaryStore: rootStore.summaryStore,
    emailStore: rootStore.emailStore,
    permissionStore: rootStore.permissionStore,
    syncStore: rootStore.syncStore,
    outgoingStore: rootStore.outgoingStore,
    ticketStore: rootStore.ticketStore,
  };
  return initializedStores;
};

export const stores = initStores(); // _stores || initStores();

export const StoreContext = React.createContext(stores);
// @ts-ignore
export const StoreProvider = ({ children }) => <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
