import moment from "moment";
import { UserInfo } from "../constants/options";
import { JOB_CATEGORY, USER_INFO } from "../constants/storage-keys";
import { GetLocalStorage } from "./cryptography";
import { isWeb } from "./platform";

export const IsAuthed = () => {
  return isWeb && localStorage.USER_TOKEN != null && localStorage.USER_INFO != null;
};

export const IsSystemInited = () => {
  return GetLocalStorage(JOB_CATEGORY) != null;
};

export const GetUserProfile = () => {
  let userInfo: UserInfo = {
    id: 0, firstName: "", lastName: "", agentCompanyId: 0, pms: [], username: "" };
  if (isWeb && localStorage.USER_INFO) userInfo = GetLocalStorage(USER_INFO);
  return userInfo;
};


export const ConvertListToCommonOptions = (infoList: any[], fieldId: string, fieldLabel: string, extraField?: string) => {
  // @ts-ignore
  return infoList.map((info: any) => ({ id: info[fieldId], label: info[fieldLabel], extraField: info[extraField] }));
};

export const GetException = (exceptionStr: string) => {
  return exceptionStr.split("Error:")[exceptionStr.split("Error:").length - 1];
};

export const IsDesktop = isWeb ? window.innerWidth > 1536 : false;
export const Resident_Category_Id = 3;

// Time
export const CNDateTimeFormat = "YYYY-MM-DD HH:mm";
export const CNDateFormat = "YYYY-MM-DD";
export const AUDateTimeFormat = "DD/MM/YYYY hh:mm A";
export const AUTimeFormat = "hh:mm A";
export const AUDDateFormat = "DD/MM/YYYY";
export const AUDTimeFormat = "hh:mm A";
export const AUStrDateTimeFormat = "DD MMM YYYY HH:mm A";
export const AUStrFullDateTimeFormat = "ddd, DD MMM YYYY HH:mm A";
export const AUStrDateFormat = "DD MMM YYYY";
export const AUStrDateTimeNonYearFormat = "DD MMM HH:mm A";
export const ProcessTimeStr = (time: string) => {
  return `${moment(time).isSame(moment(), 'day') ? `Today ${moment(time).format(AUDTimeFormat)}` : moment(time).format(AUDateTimeFormat)}`;
};

// Fail Description
export const UPDATE_FAILED_MSG = "Update failed, please try again or contact admin";
export const DELETE_FAILED_MSG = "Delete failed, please try again or contact admin";
export const CREATE_FAILED_MSG = "Create failed, please try again or contact admin";
export const CREATE_FAILED_EMAIL_MSG = "Create failed: The email address you entered may already be in use. Please try a different email, or if you believe this is an error, contact the admin for assistance.";

export const AlphabetList: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

//unitNo and address
export const GetAddress = (addressLine: string) => {
  let unitNo = '';
  let street = '';
  if (addressLine) {
    [unitNo, street] = addressLine.split(/\/(.+)/);
  }
  return {
    unitNo,
    street
  };
};

export const GetErrorInfo = (err: any) => {//err is err:obj
  let input = err.response.data.message;
  const regex = /Error:/g;
  let match: RegExpExecArray | null;
  let lastMatchIndex = -1;
  
  while ((match = regex.exec(input)) !== null) {
    lastMatchIndex = match.index;
  }
  
  if (lastMatchIndex !== -1) {
    return input.substring(lastMatchIndex + "Error:".length).trim();
  }
  if (err.response.status === 403){
    return "No Permission";
  }

  return ' '
  
};


export const IsNotExpired = (dataMaps: any[]) => {
  return dataMaps.some(dataMap => {
    const expireMoment = moment(dataMap.expireDate);
    return expireMoment.isAfter(moment());
  });
  return false;
};