import {
  observable, makeObservable,
} from 'mobx';
import { Axios } from '../utilities/network';
import RootStore from './root-store';
import { AttachmentType, PlatformType, UploadSource, VmList } from '../constants/options';
import { FAxios } from '../utilities/file-network';
export default class SummaryStore {

  loading: boolean = false;

  loadingReview: boolean = false;

  loadingComment: boolean = false;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      loading: observable,
      loadingReview: observable,
      loadingComment: observable,
    });
    this.rootStore = rootStore;
  }

  getSummaryBySuburb = async (contractorId: number, fromDate: string, toDate: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Summary/MaintenanceRequest/Contractor/${contractorId}/suburb?fromDate=${fromDate}&toDate=${toDate}&platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Suburb Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }; 

  getSummaryByJob = async (contractorId: number, fromDate: string, toDate: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Summary/MaintenanceRequest/Contractor/${contractorId}/Job?fromDate=${fromDate}&toDate=${toDate}&platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Job Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }; 


  getSummaryForAllCategory = async (fromDate: string, toDate: string) => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Summary/MaintenanceRequest/Job/All?fromDate=${fromDate}&toDate=${toDate}&platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("All Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }; 

  getContractorSurveySummaryById = async (contractorId:number) => {
    this.loadingReview = true;
    try {
      const res = await Axios.get(`/api/Summary/MaintenanceRequest/Survey/Contractor/${contractorId}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Survey Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingReview = false;
    }
  }; 
  getContractorSurveyCommentById = async (contractorId:number) => {
    this.loadingComment = true;
    try {
      const res = await Axios.get(`/api/Summary/MaintenanceRequest/Survey/Comment/Contractor/${contractorId}?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("Survey Comment", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loadingComment = false;
    }
  }; 

  getSummaryByJobCategory = async () => {
    this.loading = true;
    try {
      const res = await Axios.get(`/api/Summary/MaintenanceRequest/Case/JobCategory?platformId=${PlatformType.AGENT_ADMIN}`);
      console.log("All Summary", res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }; 
}
