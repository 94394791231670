import './src/styles/global.css';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import NotificationProvider from './src/components/root/notification';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { THEME_GREEN } from './src/constants/style';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
      primary: {
        main: THEME_GREEN,
      },
    },
  });

export const wrapRootElement = ({ element, props }) => {
    return (
        <SnackbarProvider>
            <NotificationProvider>
              <ThemeProvider theme={theme}>
                <DndProvider backend={HTML5Backend}>{element}</DndProvider>
              </ThemeProvider>
            </NotificationProvider>
        </SnackbarProvider>
    );
};